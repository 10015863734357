<template>
    <PageHeader :title="title" />

    <!-- компонент таблицы -->
    <!-- <tablecustom
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    /> -->

    <!-- картка клієнта -->
    <!-- <viewcard 
      v-if="showModal == true" 
      @call="call"
      @close="showModal = false" 
      :objcard="objcard"
    ></viewcard> -->
    
</template>

<script>
import PageHeader from "@/components/page-header";
// import tablecustom from '@/components/globaltable/index';
// import viewcard from './viewnew/index';
import { SuperAdmin } from '@/API.js';
import { storeS } from "@/store";
// import { getFlag } from '@/usabilityScripts/globalMutate.js'

let apiServe = new SuperAdmin();

export default {
components: {
    PageHeader,
    tablecustom,
    // viewcard
},
data() {
    return {
        title: this.$t('AllAccounts'),
        search: '',
        showModal: false,
        dataParams: {
            status: false,
            page: "AllAccounts"
        },
        objParams:{
            page: '1',
            pageLimit: '10',
            search: '',
        },
        columns: [
            {
                name: this.$t('AccountID'),
                text: "accountId",
                align: "left",
                status: false
            },
            {
                name: this.$t("country"),
                text: "country",
                align: "left",
                status: true,
                mutate: (item) => this.getFlag(item.title)
            },
            {
                name: this.$t("name"),
                text: "userName",
                align: "left",
                status: true
            },
            {
                name: this.$t("phone_num"),
                text: "phone",
                align: "right",
                status: true
            },
        ],
        rows: [],
        objPages: {},
        objcard: {},
    };
},
created() {
    this.getdata();
},
methods: {
    call(e) {
        
        localStorage.setItem('actualCall', e)
        this.eventBus.emit('actualCall', {
                status: true
            })
    },
    getdata(page){
        // phone accountId email
        // this.objParams.search = this.objParams.search == undefined ? "" : this.objParams.search
        // // let method = 'phone';
        // // if(this.objParams.search != undefined && this.objParams.search.length < 13) {
        // //     method = 'phone'
        // // } else if(this.objParams.search != undefined && this.objParams.search.includes('-')){
        // //     method = 'accountId'
        // // } else if(this.objParams.search != undefined && this.objParams.search.includes('@')){
        // //     method = 'email'
        // // }
        // this.objParams.page = page != undefined ? page : '1';
        apiServe.getListCompany(this.objParams).then(result => {
            if(result.status == 'done') {
                this.rows = result.data.accounts
                var pages = {
                    links: result.data.pages.items,
                    page: 1,
                    show: result.data.show,
                    total: result.data.total
                }
                this.objPages = pages;
            } else if(result.status == 'error'){
                this.$toast.error(this.$t('error') + "# 1800");
            }
        })
    },
    open: function(e){
        // apiServe.getCard(e.phone).then(result => {
        //     if(result.status == 'done') {
        //         this.objcard = result.data
        //         this.showModal = true
        //     }
        // })
    },
    changeFilter(name, value){
        if(!name.type && value != undefined){
            if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
            } else if (name == 'countryFilter') {
                var arrCountry = [];
                arrCountry.push(value)
                this.objPages[name] = arrCountry
            } else {
                this.objParams[name] = value;
            }
        this.getdata();
        }
    },
    searchB(e){
      this.objParams.search = e
      this.getdata()
    },
    changelimit: function(limit){
        this.objParams.pageLimit = limit;
        this.getdata();
    },
    getFlag(e){
        return getFlag(e)
    }
},
computed: {
    user() {
        return storeS.userbase
    },
    perms() {
        return storeS.perms
    }
},
mounted() {
    this.eventBus.on('saveAcc', (status) => {
        if(status == true) {
            this.getdata();
        }
    })
}
};
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
